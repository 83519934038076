import CONFIG_PROD from '../../_doc/config-prod.js'
import CONFIG_ALPHA from '../../_doc/config-alpha.js'
var host = document.location.host

let CONFIG = null

if (host && (host.includes('localhost') || host.includes('alpha'))) {
	CONFIG = CONFIG_ALPHA
} else {
	CONFIG = CONFIG_PROD
}
export {CONFIG}
