export default {
    state: {
        publicPath: process.env.BASE_URL,
        data: {
            metaError: 'Não foi possível carregar a meta API',
        }
    },
    mutations: {
        updateMeta(state, metaData) {
            state.data = metaData
        }
    }
}