<template>
  <div id="app">
    <router-view v-if="meta_loaded === true" />
    <div v-if="meta_loaded === false" class="loading-wrapper">
      <loading></loading>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loading from './components/utils/Loading'
import {CONFIG} from '@/config/config.js'

export default {
  components: {
    'loading': Loading
  },
  name: 'App',
  data () {
    return {
      meta_loaded: false,
      route: this.$route.name,
      blank_routes: ['SemPermissao'],
      mainRoute: this.$route.name,
      online: navigator.onLine,
      no_auth_routes: [
        'Home'
      ]
    }
  },
  methods: {
    setNewEntidade(){
      axios.post(`${CONFIG.URL_PROCESSOS_API}/v1/setting/entity/`)
      .then((response) => {
        this.$store.dispatch("getEntittySettings")
      })
      .catch((error) => {
        console.error("FALHA AO CRIAR NOVA ENTIDADE")
      })
    },
    initMaterialize () {
      setTimeout(() => M.Tooltip.init(document.querySelectorAll('.tooltipped')), 100)
    }
  },
  mounted () {
    //'.preload'
    document.getElementsByClassName('preload')[0].style.display = 'none'
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "updateMeta") {
        this.meta_loaded = true
        this.initMaterialize()
      }
    })
  }
}
</script>