import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'


import router from './router'
import store from './store'

import './assets/sass/style.scss'
import '../node_modules/materialize-css/dist/js/materialize.min'

import {CONFIG} from '@/config/config.js'

Vue.config.productionTip = false

new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App),
  methods: {
    getMeta () {
      axios.get(`${CONFIG.URL_META_API}/v2/data/${CONFIG.APP_ID}/`)
        .then(response => {
          this.$store.commit('updateMeta', response.data.data.MetaAPI.itens)
        })
        .catch(error => {
          console.error(error)
          // TODO: handle MetaAPI error here
        })
    },
    setRequestsInterceptors () {
      axios.interceptors.request.use(function (config) {
        if (localStorage.token) {
          config.headers['Authorization'] = `Bearer ${localStorage.token}`
        }
        return config
      })

      axios.interceptors.response.use((response) => {
        return Promise.resolve(response) // Nenhuma interferência aqui por enquanto
      }, (error) => {
        let response = error.response
        // Se um token de usuário existe no localStorage e o retorno de qualquer API for 403
        if (response.status === 403 && response.config.url.indexOf('/v1/login/') < 0) {
          console.error('Token inválido/expirado recusado pela API.')
        } else if (response.status === 401 && error.config.url.includes('auth-api') === false) {
          if (this.$route.path !== '/') {
            this.$router.push('/')
          }
          return Promise.reject(error)
        } else {
          return Promise.reject(error)
        }
      })
    }
  },
  mounted () {
    this.setRequestsInterceptors()
    this.getMeta()
  }
})

const prod = process.env.NODE_ENV === 'production'
const shouldSW = 'serviceWorker' in navigator && prod
const shouldSWDev = 'serviceWorker' in navigator && !prod
if (shouldSW) {
  navigator.serviceWorker.register('/service-worker.js', {scope: '/'}).then((reg) => {
    console.log(`Service Worker Registered: ${reg.scope}`)
  })
} else if(shouldSWDev) {
  navigator.serviceWorker.register('/static/js/service-worker-dev.js', {scope: '/'}).then((reg) => {
    console.log(`Service Worker Registered(DEV): ${reg.scope}`)
  })
}
